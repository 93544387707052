import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { UtilidadesService } from '../services/utilidades.service';
import { MatDialog } from '@angular/material/dialog';
import { RestaturarContraseniaComponent } from './restaturar-contrasenia/restaturar-contrasenia.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  segundos: number;
  private contador_suscripcion: Subscription;
  segundos_iniciales: number = 30;
  segundos_pausados:number = 0;
  nombre:String = ""
  login_formulario: FormGroup;
  inputType: string = 'password'
  visible:boolean = false;
  login_oportunidades:number = 0
  login_intentos:number = 0
  pagina_activa:boolean = true
  
  constructor(private formBuilder:FormBuilder, 
              private router:Router,
              public dialog: MatDialog,
              private loginService:LoginService,
              public utilidadesService:UtilidadesService
             ){
                localStorage.getItem('segundos')!=null ? this.segundos_pausados = Number(localStorage.getItem('segundos')) :  this.segundos = this.segundos_iniciales;
                this.login_oportunidades = Number(localStorage.getItem('login_oportunidades'))
                this.segundos = this.segundos_iniciales;
                if(this.login_oportunidades>=3 && localStorage.getItem('segundos')!=null ){
                  this.empezar_contador()
                }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    localStorage.setItem('segundos', String(this.segundos));
    localStorage.setItem('login_oportunidades', String(this.login_oportunidades));
  }
  
  @HostListener('keydown.enter', ['$event']) 
  handleEscapeKey(event: KeyboardEvent) {
    this.login();
  }

  ngOnInit(){
    this.login_formulario = this.formBuilder.group({
      email: ['', [Validators.required,this.validacion_correo_grupobrame]],
      password: ['', [ Validators.required, Validators.minLength(16)]],
    })
    document.addEventListener('visibilitychange', () => {
      this.pagina_activa =  !document.hidden;
      if(!this.pagina_activa){
        this.detener_contador()
      }else{
        this.segundos = this.segundos_pausados
        this.empezar_contador()
      }
    });
  }

  login(){
    if(this.login_formulario.valid ){
      this.loginService.loginByPassword(this.login_formulario.value).subscribe(response=>{ 
        localStorage.removeItem('segundos');
        localStorage.removeItem('login_oportunidades');
        this.loginService.user = response.data;
        this.loginService.token = response.token;
        this.loginService.setToken(response.token);
        this.loginService.validate_token();
        this.router.navigate(['/home']);
        
        const nombres = response.data.nombre_usuario.split(' ');
        const primerNombre = nombres[0].toLowerCase().charAt(0).toUpperCase() + nombres[0].toLowerCase().slice(1);        
        this.utilidadesService.mostrar_mensaje(`¡Bienvenido, ${primerNombre}!`);
      }, error=>{
        this.login_oportunidades+=1
        if(this.login_oportunidades>=3){
          this.login_intentos+=1;
          this.empezar_contador();
        }
        if (error.status === 401) {
          this.utilidadesService.mostrar_mensaje("Correo / contraseña incorrectos", 'error');
        } else if (error.status === 404) {
          this.utilidadesService.mostrar_mensaje("Usuario no encontrado", 'error');
        } else {
          this.utilidadesService.mostrar_mensaje("Error en el servidor. Por favor, inténtelo de nuevo más tarde.", 'error');
        }
      });
    } else {
      if (this.login_formulario.get('email').errors) {
        this.utilidadesService.mostrar_mensaje("Correo inválido, verifique su correo electrónico", 'error');
      }
    }
  }

  verifica_errores_correo(){
    if (this.login_formulario.get('email').errors) {
      this.utilidadesService.mostrar_mensaje("Correo inválido, verifique su correo electrónico", 'error');
    }
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
    } else {
      this.inputType = 'text';
      this.visible = true;
    }
  }

  empezar_contador() {
    this.segundos = this.segundos_pausados!=0 ? this.segundos_pausados :this.segundos_iniciales * this.login_intentos;
    if(this.segundos>=90){
      this.segundos = 90;
    }
    this.contador_suscripcion = interval(1000).subscribe(() => {
      if (this.segundos > 0) {
        this.segundos--;
        this.segundos_pausados = this.segundos;
      } else {
        this.login_oportunidades = 0;
        localStorage.removeItem('segundos');
        localStorage.removeItem('login_oportunidades');
        this.detener_contador();
      }
    });
  }
  detener_contador() {
    if (this.contador_suscripcion) {
      this.contador_suscripcion.unsubscribe();
    }
  }

  validacion_correo_grupobrame(control) {
    let correo = control.value as string;
    if (correo && correo.endsWith('@grupobrame.com')) {
      return null; // Válido
    } else {
      return { 'correoInvalido': true }; // Inválido
    }
  }
  
  modal_restaturar_contrasenia(){
    this.dialog.open(
      RestaturarContraseniaComponent, 
      {
        width: '500px',
        minHeight:'310px',
        panelClass: 'custom-dialog-content',
        data:{
          email : this.login_formulario.get('email').value ?  this.login_formulario.get('email').value : ''
        }
      }
    );
  }

}
