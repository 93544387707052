import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';
import { UtilidadesService } from '../../services/utilidades.service';

@Component({
  selector: 'app-restaturar-contrasenia',
  templateUrl: './restaturar-contrasenia.component.html',
  styleUrls: ['./restaturar-contrasenia.component.scss', '../login.component.scss']
})
export class RestaturarContraseniaComponent implements OnInit {
  contrasenia_formulario: FormGroup;
  enviado:boolean = false
  constructor(private formBuilder:FormBuilder,
              private loginService:LoginService,
              private utilidadesService:UtilidadesService,
              public dialog:MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any){}
              
  ngOnInit(){
    this.contrasenia_formulario = this.formBuilder.group({
      email: [this.data.email ? this.data.email : '', [Validators.required,this.validacion_correo_grupobrame]],
    })
  }

  validacion_correo_grupobrame(control: FormControl) {
    let correo = control.value as string;
    if (correo && correo.endsWith('@grupobrame.com')) {
      return null; // Válido
    } else {
      return { 'correoInvalido': true }; // Inválido
    }
  }

  enviar_correo(){
    this.loginService.enviar_contrasenia(this.contrasenia_formulario.value).subscribe({
      next: () => {
        this.enviado = true;
      },
      error: () => {
        this.utilidadesService.mostrar_mensaje('Ha ocurrido un error al intentar recuperar contraseña', 'error');
      }
    });
  }
}
