<div class="flex min-h-full">
  <div class="relative hidden w-0 flex-1 lg:block" >
    <div class="absolute inset-0 h-full w-full object-cover flex items-center justify-center" 
          style="background-image: url('assets/img/backgrounds/login-bg.png'); background-repeat: no-repeat; background-size: cover;" 
          alt="Fondo de login BrameOn">
      <img src="assets/img/images/Logo-BrameOn-Blanco.png" 
            style="width: 400px;" 
            alt="Logo BrameOn">
    </div>
  </div>

  <div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
    <div class="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight  text-center mb-10n poppins-bold" style="color: #4678E5;">Iniciar sesión</h2>
      </div>

      <div class="mt-10">
        <form action="#" method="POST" class="space-y-6" [formGroup]="login_formulario">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2 poppins-bold" for="grid-password">
                Correo
              </label>
              <input class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-#9eb4f9 "
                      style="background-color: white!important; border-radius: 10px;"
                      placeholder="Ej. nombre.apellido@grupobrame.com"
                      formControlName="email"
                      type="email"
                      (blur)="verifica_errores_correo()">
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2 poppins-bold" for="grid-password">
                Contraseña
              </label>
              <div class="flex flex-row items-center justify-end">
                <input class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none"
                        style="background-color: white!important; border-radius: 10px; padding-right: 70x!important; position: relative;" 
                        placeholder="******************" 
                        maxlength="16" 
                        [type]="inputType" 
                        formControlName="password">

                <mat-icon  style=" font-size: 20px; margin-top: -8px; position: absolute;"
                          (click)="toggleVisibility()"
                          *ngIf="visible else Visualizar">
                  visibility
                </mat-icon>

                <ng-template #Visualizar>
                  <mat-icon  style=" font-size: 20px; margin-top: -8px; position: absolute;"
                              (click)="toggleVisibility()">
                    visibility_off
                  </mat-icon>
                </ng-template>
                
              </div>
            
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm leading-6">
              <a class="font-semibold hover:cursor-pointer poppins-bold" 
                  style="color:#85AAFF"
                  (click)="modal_restaturar_contrasenia()" >
                  ¿Olvidaste tu contraseña?
              </a>
            </div>
            <div class="text-sm leading-6" *ngIf="login_oportunidades>=3">
              <a href="#" class="font-semibold text-black">{{segundos}} Segundos</a>
            </div>
          </div>
          <div>
            <button type="button"
                    class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-medium leading-6 poppins-bold"
                    [class]="!login_formulario.valid ? 'inhabilitar-boton' : 'habilitar-boton'"
                    (click)="login()" mat-raised-button 
                    [disabled]="login_formulario.valid && login_oportunidades<3 ? false : true " >
              Ingresar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>