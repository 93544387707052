<h2 class="text-center mb-6  texto-azul-primario poppins-bold mt-4">NUEVA CONTRASEÑA</h2>

<div class="p-4 pt-0" style="border-radius: 20px;" *ngIf="!enviado">
  <mat-dialog-content>
    <p class="text-center poppins-regular">
      El tiempo de respuesta para obtener una nueva contraseña es de 24 horas
    </p>

    <form [formGroup]="contrasenia_formulario" class="mt-3 mb-3">
      <div class="w-full">
        <label for="" class="block text-sm font-medium leading-6 text-gray-900 poppins-semibold">
          Ingresa tu correo electrónico
        </label>

        <div class="relative mt-2 rounded-md ">
          <input type="email" formControlName="email" placeholder="" aria-invalid="true"
                  class="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6">
        </div>
      </div>

      <p class="text-sm text-black poppins-regular" id="email-error"
          *ngIf="contrasenia_formulario.get('email').hasError('correoInvalido')">
        El correo debe de ser con dominio grupobrame.com
      </p>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="w-full flex justify-end">
    <button type="button" mat-raised-button (click)="dialog.closeAll()"
            class="flex justify-center rounded-md px-3 py-1.5 text-sm poppins-bold leading-6 font-medium text-white bg-orange-500">
      Cancelar
    </button>

    <button type="button" (click)="enviar_correo()" mat-raised-button [disabled]="contrasenia_formulario.invalid"
            class="flex justify-center rounded-md px-3 py-1.5 text-sm font-medium leading-6 poppins-bold"
            [class]="contrasenia_formulario.valid ? 'bg-blue-500 text-white' : 'inhabilitar-boton'">
      Enviar
    </button>
  </mat-dialog-actions>
</div>

<div class="p-4 pt-0" style="border-radius: 20px;" *ngIf="enviado">
  <mat-dialog-content>
    <p class="text-center poppins-regular">
      Hemos recibido tu petición. Enviaremos tu nueva contraseña a tu correo electrónico en un lapso de 24 horas
    </p>
  </mat-dialog-content>

  <mat-dialog-actions class="flex justify-center">
    <button type="button" (click)="dialog.closeAll()" mat-raised-button
            class="flex bg-blue-500 justify-center w-10 rounded-md px-3 py-1.5 text-sm font-normal mt-10 leading-6 text-white poppins-semibold">
      Cerrar
    </button>
  </mat-dialog-actions>
</div>